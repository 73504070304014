import React, { useCallback, useRef, useState } from "react";
import { PdfSvg } from "../../../../assets/Svgs";
import { useAtom } from 'jotai';
import { currentStepAtom, fileDataAtom } from "../../GeneratePdf";
import Papa from 'papaparse';
import { toast } from "react-toastify";
import { isEqual, max, times } from "lodash";

const COMPONENT_STEP = 0;
const DEFAULT_DELIMETER = ',';

export default function UploadCsv() {
    const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
    const [, setFileData] = useAtom(fileDataAtom);
    const [csv, setCsv] = useState<File>();

    const csvRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const handleSubmit = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();

        if (!csv) {
            return;
        }

        Papa.parse(csv, {
            delimiter: DEFAULT_DELIMETER,
            complete: result => {
                if (result.errors.length > 0) {
                    return toast.error(
                        `Unable to Proceed with Analysis. Please ensure you select a CSV file that includes two columns: one with STOCK TICKER SYMBOLS, the other with the CURRENT MARKET VALUE of those stocks.`
                    );
                }

                const data = (result.data.filter((row: any) => !isEqual(row, [''])) as any).map((array: string[]) => {
                    return array.map(x => {
                        return x.replace(/\n|\r/g, "").toUpperCase();
                    });
                });

                const parsedData = {} as { [key: number]: string[]; };

                times(max([data[0]?.length, data.length]), Number).forEach(header => {
                    parsedData[header] = data.map((item: string[]) => item[header]);
                });

                setFileData(parsedData);
                setCurrentStep(prev => prev + 1);
            },
        });
    }, [csv, setCurrentStep, setFileData]);

    if (currentStep !== COMPONENT_STEP) {
        return <></>;
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="text-cont">
                <h2>Ethiq® Portfolio Analyzer</h2>
                <h2> Step 1: Upload CSV file of your portfolio.</h2>
                In order for Ethiq to run an analysis of your portfolio, please upload a
                CSV file that contains at least these two columns: <br /> a) ticker
                symbols of your holdings, b) corresponding market value of these
                holdings. For instructions and information on how to create this CSV
                file through your online brokerage account (e.g. Schwab, Fidelity,
                E*Trade, etc.) <a href='/help.pdf' target='_blank' className='underline' style={{ color: 'blue' }}>click here</a>. <br />
                <br />
                <span style={{ fontSize: 16.5, color: 'red' }}>
                    NOTE: Ethiq does NOT store this CSV file, and the file does NOT need any
                    personally identifiable information included (e.g. your name, date of
                    birth, SSN).
                </span>
            </div>
            <div className="button-cont">
                {csv ? (
                    <div className="csvName-cont">
                        <div className="align-center justify-center">

                            <span style={{ fontSize: "26px" }} className="ft4" >   <PdfSvg />{" "}{csv?.name}</span>
                        </div>
                        <input
                            ref={csvRef}
                            type="file"
                            accept=".csv"
                            onChange={(e) => {
                                if (e.target.files) {
                                    setCsv(e.target.files[0]);
                                }
                            }}
                            style={{ display: "none" }}
                        />
                        <button
                            type="button"
                            className="btn"
                            onClick={() => {
                                csvRef.current.click();
                            }}
                        >
                            CLICK HERE TO SELECT A DIFFERENT FILE
                        </button>
                    </div>
                ) : (
                    <>
                        <button
                            onClick={() => {
                                csvRef.current.click();
                            }}
                            className="btn1 bg-primary"
                        >
                            Select File
                        </button>
                        <input
                            ref={csvRef}
                            type="file"
                            accept=".csv"
                            onChange={e => e.target.files?.[0] && setCsv(e.target.files[0])}
                            style={{ display: "none" }}
                        />
                    </>
                )}

                <button
                    type='submit'
                    className={`btn2 ${csv ? "bg-primary font-white " : ""}`}
                >
                    Upload
                </button>
            </div>
        </form>
    );
}
