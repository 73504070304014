import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from '../../../GA';
import logo from "../../assets/red-logo.svg";
import './header.css';

export default function Header() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }, [location.pathname]);

    return (
        <header>
            <div className="header-inner">
                <Link to='/'>
                    <img src={logo} alt="" />
                </Link>

                <div className="links">
                    <Link to='/'>Home</Link>
                    <Link to='/about'>About</Link>
                    <a href='mailto:info@ethiq.org'>Contact</a>
                </div>
            </div>
        </header>
    );
}
