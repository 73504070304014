import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import InputContainer from '../../ui/input-container/InputContainer';
import { toast } from 'react-toastify';
import './forgotPassword.css';
import { resetPassword } from '../../../actions/user';

const ForgotPassword: React.FC = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validate, setValidate] = useState(false);
    const [completed, setCompleted] = useState(false);

    const { token } = useParams();

    return (
        <div className='home forgot-password'>
            <div className="shadow-box">
                <form className={`${completed && 'completed'}`} onSubmit={async (e) => {
                    e.preventDefault();
                    setValidate(true);

                    if (password !== confirmPassword) {
                        return;
                    }

                    try {
                        await resetPassword(token as string, password);
                        setCompleted(true);
                    } catch (error) {
                        toast.error('Invalid reset link!');
                        console.error(error);
                    }
                }}>
                    <div className="heading">Chrome Extension Password Reset</div>

                    {completed ? (
                        <p>You have successfully reset your password.
                            You may return to Ethiq’s Chrome Extension to log in.</p>
                    ) : (
                        <>
                            <InputContainer
                                value={password}
                                setValue={setPassword}
                                error={validate && password.length < 8}
                                errorMessage='At least 8 characters'
                                label='Password'
                                type='password'
                                minLength={8}
                            />

                            <InputContainer
                                value={confirmPassword}
                                setValue={setConfirmPassword}
                                error={validate && confirmPassword !== password}
                                errorMessage='Passwords do not match'
                                label='Retype Password'
                                type='password'
                            />
                            <button className="primary-button" type='submit'>Confirm</button>
                        </>
                    )}
                </form>
            </div>
        </div>
    );
}

export default ForgotPassword;
