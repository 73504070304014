import { useState } from "react";
import pdfs from "../../assets/pdfs.png";
import chrome from "../../assets/chrome.png";
import { useNavigate } from "react-router-dom";
import "./home.css";
import MobileView from "../../ui/MobileView";
import * as AccessCode from "../../../actions/accessCode";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { trackEvent } from "../../../GA";

export const accessCodeAtom = atomWithStorage("accessCodeAtom", "");

const INVALID_CODE_LABEL = 'Code Invalid.';
const USED_CODE_LABEL = 'This access code has already been used.';

export default function Home() {
  const navigate = useNavigate();

  const [areTermsAccepted, setAreTermsAccepted] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const [accessCodeError, setAccessCodeError] = useState<null | string>(null);
  const [accessCode, setAccessCode] = useAtom(accessCodeAtom);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setShowTermsError(!areTermsAccepted);

    if (!areTermsAccepted) {
      return;
    }

    trackEvent({
      category: `Click the "Begin" button`,
      action: `Click "Begin" with code ${accessCode}`,
      label: `Click "Begin" with code ${accessCode} to validate the code`,
    });

    setAccessCodeError(null);

    try {
      const { valid, wasUsed } = await AccessCode.getAccessCode(accessCode);

      if (!valid) {
        setAccessCodeError(INVALID_CODE_LABEL);
      }

      if (wasUsed) {
        setAccessCodeError(USED_CODE_LABEL);
      }

      trackEvent({
        category: `Click the "Begin" button after validation`,
        action: `Click "Begin" with code ${accessCode} - code ${valid ? "VALID" : "INVALID"
          }`,
        label: `Click "Begin" with ${valid ? "VALID" : "INVALID"
          } code ${accessCode}`,
      });

      if (valid) {
        navigate("/generate-pdf");
      }
    } catch (error) {
      setAccessCodeError(INVALID_CODE_LABEL);
    }
  }

  return (
    <div className="home">
      <div className="shadow-box">
        <div className="desktop-cont">
          <img style={{ height: "350px" }} src={pdfs} alt="" />
          <form className="textSide" onSubmit={handleSubmit}>
            <h2>Ethiq Portfolio Analyzer</h2>

            <h3>
              Analyze your investments through the lens of issues you care about
              most.
            </h3>

            <p>
              Ethiq’s Portfolio Analyzer creates a PDF report of your
              investments and their unique connections to key issues,
              legislation, politicians, states, countries, etc. This report will
              inform you as to which politicians your portfolio is supporting,
              which pieces of legislation corporations in your portfolio support
              or oppose, a breakdown of corporate giving by political parties,
              whether or not your portfolio helps support state and federal
              governments, and much more.
            </p>

            <p className="ft2 mt3" >
              If you don’t have an access code and would like to try this tool,
              email us <a href="mailto:info@ethiq.org">here</a>.
            </p>
            <div className="input-cont">
              <input
                onChange={(e) => setAccessCode(e.target.value)}
                className="access-code-input"
                placeholder="One Time Access Code"
                type="text"
              />
              {accessCodeError && <p> {accessCodeError}</p>}
            </div>
            <label className="pointer in-row mb3 ml0">
              <input
                type="checkbox"
                onChange={(e) => setAreTermsAccepted(e.target.checked)}
                checked={areTermsAccepted}
                className="checkbox-container"
                style={{ display: "none" }}
              />
              <div
                className={classNames(
                  areTermsAccepted && "check-bg",
                  `checkBox`
                )}
              ></div>
              <p className="ml2">
                I’ve read and agree to{" "}
                <Link target="_blank" to="/privacy" rel="noreferrer">
                  the privacy policy
                </Link>{" "}
                and{" "}
                <Link target="_blank" to="/terms" rel="noreferrer">
                  {" "}
                  terms of service.
                </Link>
              </p>
            </label>
            <div className="begin-btn-cont">
              <button type="submit" className="beginBtn">
                Begin
              </button>

              {showTermsError && (
                <p>
                  Please agree to the privacy policy and terms of
                  service.
                </p>
              )}
            </div>
          </form>
        </div>
        <MobileView />
      </div>

      <a
        href="https://chrome.google.com/webstore/detail/ethiq/ledenamcmhhcjlolpkcglgcbbcjfmahg?hl=en-US"
        target="_blank"
        rel="noreferrer"
        className="button-container"
        onClick={() => {
          trackEvent({
            category: `"Download the FREE Ethiq® Chrome Extension" click`,
            action: `Home page: "Download the FREE Ethiq® Chrome Extension" click`,
            label: `Home page: "Download the FREE Ethiq® Chrome Extension" click`
          });
        }}
      >
        <img src={chrome} alt="" />
        <button>Download the FREE Ethiq® Chrome Extension</button>
      </a>
    </div>
  );
}
