import React, { useCallback, useMemo, useState } from 'react';
import { backBtnAtom, currentStepAtom, fileDataAtom, portfolioAtom } from '../../GeneratePdf';
import { useAtom } from 'jotai';
import './selectEntries.css';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { uniqBy } from 'lodash';
import { useQuery } from 'react-query';
import { getTickers } from '../../../../../actions/ticker';
import Reset from '../../../../assets/reset.svg';

const COMPONENT_STEP = 1;

type TColumnValue = { value: string | number, index: number };

export default function SelectEntries() {
    const { data: tickers } = useQuery("tickers", getTickers, { refetchOnWindowFocus: false });
    const [backBtn] = useAtom(backBtnAtom);
    const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
    const [fileData] = useAtom(fileDataAtom);
    const [, setPortfolio] = useAtom(portfolioAtom);

    const [stockTickerHeader, setStockTickerHeader] = useState<string | number | null>(null);
    const [amountHeader, setAmountHeader] = useState<string | number | null>(null);

    const [stockTickerValues, setStockTickerValues] = useState([] as TColumnValue[]);
    const [amountValues, setAmountValues] = useState([] as TColumnValue[]);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const headers = useMemo(() => Object.keys(fileData).map(Number), [fileData]);

    const handleSelectColumns = useCallback((header: number) => (e: React.MouseEvent) => {
        e.preventDefault();

        if (stockTickerHeader !== null && amountHeader !== null) {
            return;
        }

        if (stockTickerHeader === null) {
            const stockTickerValues = fileData[header]
                .map((value: string, index: number) => ({ value, index }))
                .filter(x => tickers?.find(({ stockTicker }) => stockTicker === x.value));

            if (stockTickerValues.length === 0) {
                return toast.error(`Stock ticker column selected does not contain valid tickers!`);
            }

            setStockTickerHeader(header);
            setStockTickerValues(stockTickerValues);

            return toast.success(`Stock ticker column selected successfully!`);
        }

        setAmountHeader(header);

        const amountValues = fileData[header]
            .map((value: string, index: number) => ({
                value: parseAmount(value),
                index,
            }))
            .filter((amountItem: TColumnValue) => (
                !isNaN(amountItem.value as number)
                && stockTickerValues.find(tickerItem => amountItem.index === tickerItem.index)
            ));

        setAmountValues(amountValues);
        setStockTickerValues(prev => prev.filter(tickerItem => !!amountValues.find(amountItem => amountItem.index === tickerItem.index)));

        return toast.success(`Total market value column selected successfully!`);
    }, [amountHeader, stockTickerHeader, fileData, tickers, stockTickerValues]);

    const handleResetColumns = useCallback((e: React.MouseEvent) => {
        e.preventDefault();

        setAmountHeader(null);
        setAmountValues([]);
        setStockTickerHeader(null);
        setStockTickerValues([]);
    }, []);

    const showHeaderClarification = useCallback((header: number) => {
        switch (header) {
            case stockTickerHeader: {
                return '(Stock Ticker Column)';
            }

            case amountHeader: {
                return '(Total Market Value Column)';
            }

            default:
                return '';
        }
    }, [amountHeader, stockTickerHeader]);

    const handleSubmit = useCallback((e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage(null);

        if (stockTickerHeader === null || amountHeader === null) {
            return setErrorMessage(`Error: Please select the columns.`);
        }

        if (!amountValues.length) {
            return setErrorMessage(`Error: All total market values need to be numeric.`);
        }

        setPortfolio(uniqBy(amountValues.map((item, index) => ({
            stockTicker: stockTickerValues[index].value as string,
            value: item.value as number,
        })), 'stockTicker'));

        setCurrentStep(prev => prev + 1);
    }, [amountHeader, setCurrentStep, setPortfolio, stockTickerHeader, amountValues, stockTickerValues]);

    const tableHead = useMemo(() => (
        headers.map((header: number) => (
            <th
                className={classNames([stockTickerHeader, amountHeader].includes(header) && 'selected-column', 'pointer')}
                key={header}
                onClick={handleSelectColumns(header)}>
                {header}
                {showHeaderClarification(header)}
            </th>
        ))
    ), [headers, amountHeader, handleSelectColumns, showHeaderClarification, stockTickerHeader]);

    const tableBody = useMemo(() => (
        headers.map((header: number) => (
            <tr key={header}>
                {headers.map((key: number) => {
                    const isInSelectedColumn = [stockTickerHeader, amountHeader].includes(key);
                    const isSelectedValue = isInSelectedColumn && !!(
                        stockTickerValues.find(item => item.index === header)
                        || amountValues.find(item => item.index === header)
                    );

                    return (
                        <td
                            className={classNames(
                                isInSelectedColumn && 'selected-column',
                                isSelectedValue && 'selected-value',
                                'pointer'
                            )}
                            key={`${key}-${header}`}
                            onClick={handleSelectColumns(key)}>
                            {fileData[key][header]}
                        </td>
                    );
                })}
            </tr>
        ))
    ), [headers, fileData, amountHeader, amountValues, handleSelectColumns, stockTickerHeader, stockTickerValues]);

    if (currentStep !== COMPONENT_STEP || !headers || !tickers) {
        return <></>;
    }

    return (
        <>
            {backBtn}
            <form onSubmit={handleSubmit} className='width100'>
                <div className="text-cont" style={{ color: 'black' }}>
                    <h2>Ethiq® Portfolio Analyzer</h2>
                    <b> FIRST</b>, click the column with <b>STOCK/TICKER SYMBOLS</b>
                    <br />
                    <b>SECOND</b>, click the column with the <b>TOTAL MARKET VALUE</b> (not individual stock price) of those stock/ticker symbols.
                </div>

                <div className="select-entries-table-container">
                    <table className='select-entries-table mt6'>
                        <thead>
                            <tr>
                                {tableHead}
                            </tr>
                        </thead>

                        <tbody>
                            {tableBody}
                        </tbody>
                    </table>
                </div>

                <div className='mt1 justify-end'>
                    <div className="in-row align-center pointer" onClick={handleResetColumns}>
                        <img
                            src={Reset}
                            alt="reset"
                            className='mr0'
                            width='18px'
                        />
                        <small style={{ color: '#15289d' }}>Reset Columns Selection</small>
                    </div>
                </div>

                <div className='justify-center'>
                    <button
                        type='submit'
                        disabled={stockTickerHeader === null || amountHeader === null}
                        className='beginBtn'
                    >
                        Confirm
                    </button>
                </div>

                {errorMessage && <p className='error-message mt2'>{errorMessage}</p>}
            </form>
        </>
    );
}

function parseAmount(amount: string) {
    try {
        const parsed = amount.replaceAll(',', '').replaceAll('$', '');
        return (parsed === '' || parsed === null) ? NaN : parseFloat(parsed);
    } catch {
        return NaN;
    }
}
