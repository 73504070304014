import React, { useMemo } from 'react';
import { currentStepAtom } from '../GeneratePdf';
import { useAtom } from 'jotai';

const steps = [
    <>Portfolio <br /> Upload</>,
    <>Select <br /> Colums</>,
    <>Values <br /> Questionaire</>,
    <>Upload <br /> Logo</>,
    <>Confirm</>,
];

interface Props {
    promptRequired: boolean;
}

const StepProgress: React.FC<Props> = ({ promptRequired }) => {
    const [currentStep] = useAtom(currentStepAtom);

    const stepList = useMemo(() => {
        return promptRequired ? steps : steps.filter((_, index) => index !== 2);
    }, [promptRequired]);

    return (
        <div className="progress-cont">
            {stepList.map((step, index) => (
                <React.Fragment key={index}>
                    <div className={`status step${index + 1}`}>
                        {currentStep === index && step}
                    </div>

                    {currentStep === index ? (
                        <div className="outer-circle">
                            <div className="circle bg-primary" />
                        </div>
                    ) : (
                        <div>
                            <div className="circle" />
                        </div>
                    )}

                    <div className="line" />
                </React.Fragment>
            ))}
        </div>
    );
};

export default StepProgress;
