import React, { useEffect, useState } from "react";
import UploadCsv from "./steps/upload-csv/UploadCsv";
import { useAtom, atom } from "jotai";
import "./generatePdf.css";
import { accessCodeAtom } from "../home/Home";
import { useNavigate } from "react-router-dom";
import StepProgress from "./step-progress/StepProgress";
import SelectEntries from "./steps/select-entries/SelectEntries";
import Questionaire from "./steps/questionnaire/Questionnaire";
import * as AccessCode from "../../../actions/accessCode";
import Confirm from "./steps/confirm/Confirm";
import ViewPdf from "./steps/view-pdf/ViewPdf";
import { BackSvg } from "../../assets/Svgs";
import UploadLogo from "./steps/upload-logo/UploadLogo";

export const currentStepAtom = atom(0);
export const fileDataAtom = atom({} as { [key: number]: string[]; });
export const logoAtom = atom(null as null | File);
export const generateLoading = atom(false);
export const backBtnAtom = atom(<></>);

export const portfolioAtom = atom(
  [] as { stockTicker: string; value: number; }[]
);
export const promptChoicesAtom = atom(
  [] as { promptId: number; choiceNumber: number; }[]
);

const GeneratePdf: React.FC = () => {
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const navigate = useNavigate();
  const [accessCode, setAccessCode] = useAtom(accessCodeAtom);
  const [, setPromptChoices] = useAtom(promptChoicesAtom);
  const [, setPortfolio] = useAtom(portfolioAtom);
  const [, setBackBtn] = useAtom(backBtnAtom);
  const [promptRequired, setPromptRequired] = useState<boolean>();

  useEffect(() => {
    const handleResetAccessCode = (isValid: boolean) => {
      if (isValid) {
        return;
      }

      navigate("/");
      setAccessCode("");
    };

    AccessCode.getAccessCode(accessCode)
      .then(({ valid, promptRequired }) => {
        handleResetAccessCode(valid);
        setPromptRequired(promptRequired);
      })
      .catch(() => handleResetAccessCode(false));
  }, [accessCode, setAccessCode, navigate]);

  useEffect(() => {
    setCurrentStep(0);
    setPromptChoices([]);
    setPortfolio([]);
  }, [setCurrentStep, setPromptChoices, setPortfolio]);

  useEffect(() => {
    setBackBtn(
      <BackSvg
        className="backBtn pointer"
        onClick={() => {
          setCurrentStep(prev => !prev ? prev : prev - 1);
        }}
      />
    );
  }, [setBackBtn, setCurrentStep]);

  console.log(Number(promptRequired));

  return (
    <div className="upload">
      <div className="shadow-box" style={{ minHeight: [1, 4].includes(currentStep) ? 500 : 400 }}>
        <UploadCsv />
        <SelectEntries />
        {promptRequired ? <Questionaire step={2} /> : <></>}
        <UploadLogo step={2 + Number(promptRequired)} />
        <Confirm step={3 + Number(promptRequired)} />
        <ViewPdf step={4 + Number(promptRequired)} />
      </div>

      {(currentStep <= 2 + Number(promptRequired)) && <StepProgress promptRequired={!!promptRequired} />}
    </div>
  );
};

export default GeneratePdf;
