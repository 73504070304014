import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getPrompts } from "../../../../../actions/prompt";
import { currentStepAtom, promptChoicesAtom } from "../../GeneratePdf";
import { useAtom } from "jotai";
import "./questionnaire.css";
import classNames from "classnames";
import { toast } from "react-toastify";
import { BackSvg } from "../../../../assets/Svgs";

interface Props {
  step: number;
}

const Questionnaire: React.FC<Props> = ({ step }) => {
  const { data: prompts } = useQuery("prompts", getPrompts, { refetchOnWindowFocus: false });
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [promptChoices, setPromptChoices] = useAtom(promptChoicesAtom);
  const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
  const [choiceNumber, setChoiceNumber] = useState<number | null>(null);

  const currentPromptAnswer = useMemo(() => {
    return promptChoices.find(x => x.promptId === prompts?.[currentPromptIndex]?.id);
  }, [promptChoices, currentPromptIndex, prompts]);

  useEffect(() => {
    if (currentPromptAnswer) {
      setChoiceNumber(currentPromptAnswer.choiceNumber);
    }
  }, [currentPromptAnswer]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (choiceNumber === null || !prompts) {
        return toast.error(
          `Please select an option`,
        );
      }

      setPromptChoices((prev) => [
        ...prev.filter(x => x.promptId !== prompts[currentPromptIndex].id),
        { promptId: prompts[currentPromptIndex].id, choiceNumber },
      ]);
      setChoiceNumber(null);

      const nextPromptIndex = currentPromptIndex + 1;

      if (!prompts[nextPromptIndex]) {
        return setCurrentStep((prev) => prev + 1);
      }

      setCurrentPromptIndex(nextPromptIndex);
    },
    [
      choiceNumber,
      currentPromptIndex,
      prompts,
      setCurrentStep,
      setPromptChoices,
    ]
  );
  const currentPrompt = useMemo(
    () => prompts?.[currentPromptIndex],
    [prompts, currentPromptIndex]
  );

  const handleBackClick = useCallback(() => {
    if (currentPromptIndex === 0) {
      return setCurrentStep(prev => prev - 1);
    }

    setCurrentPromptIndex(prev => prev - 1);
  }, [currentPromptIndex, setCurrentStep, setCurrentPromptIndex]);

  const displayOption = useCallback((option: TOption) => {
    const isChecked = choiceNumber === option.value;

    return (
      <label key={option.value}>
        <input
          type="checkbox"
          onChange={(e) =>
            setChoiceNumber(e.target.checked ? option.value : null)
          }
          checked={isChecked}
          className="checkbox-container"
          style={{ display: "none" }}
        />
        <div
          className={classNames(
            isChecked && "check-bg",
            `checkBox`
          )}
        ></div>
        <p dangerouslySetInnerHTML={{ __html: option.label }} />
      </label>
    );
  }, [choiceNumber]);

  if (currentStep !== step || !prompts) {
    return <></>;
  }

  return (
    <>
      <BackSvg
        className="backBtn pointer"
        onClick={handleBackClick}
      />

      <div style={{ position: "relative", width: "90%" }}>
        <div className="prompt-page">
          Prompt {currentPromptIndex + 1} of {prompts.length}
        </div>
        <div className="text-cont">
          <h2 style={{ fontWeight: 400 }}>Step {step + 1}: Values Questionnaire</h2>
          By sharing your opinion on these 8 prompts, Ethiq can output an analysis
          best aligned with your unique values.
        </div>

        <form onSubmit={handleSubmit}>
          <div style={{ height: "120px" }}>
            <h3 className="pt6 pb6">{currentPrompt?.title}</h3>
          </div>

          <div className="question">
            {options.map(displayOption)}
          </div>

          <button
            className={`${choiceNumber !== null ? "beginBtn" : "beginBtnDisabled"}`}
            type="submit"
          >
            {prompts[currentPromptIndex + 1] ? "Next" : "Submit"}
          </button>
        </form>
      </div>
    </>
  );
};

type TOption = {
  label: string;
  value: number;
};

const options: TOption[] = [
  {
    label: "Strongly <br> Disagree",
    value: -2,
  },
  {
    label: "Disagree",
    value: -1,
  },
  {
    label: "No <br> Opinion",
    value: 0,
  },
  {
    label: "Agree",
    value: 1,
  },
  {
    label: "Strongly <br> Agree",
    value: 2,
  },
];

export default Questionnaire;
