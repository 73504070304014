import React from "react";
import { PreviewSvg } from "../../../../assets/Svgs";
import { useAtom } from "jotai";
import { currentStepAtom } from "../../GeneratePdf";
import { downloadPdf, viewPdf } from "../../../../../actions/generatePdf";
import { pdfBlob } from "../../steps/confirm/Confirm";
import { trackEvent } from "../../../../../GA";
import { accessCodeAtom } from "../../../home/Home";
import "./ViewPdf.css"

interface Props {
  step: number;
}

const ViewPdf: React.FC<Props> = ({ step }) => {
  const [blobData] = useAtom(pdfBlob);
  const [currentStep] = useAtom(currentStepAtom);
  const [accessCode] = useAtom(accessCodeAtom);

  if (currentStep !== step) {
    return <></>;
  }

  return (
    <div className="text-cont">
      <h2>Analysis complete, your PDF is ready.</h2>
      <PreviewSvg />

      <div className="button-conta">
        <button
          className="downloadBtn bg-primary"
          onClick={() => {
            downloadPdf(blobData);

            trackEvent({
              category: `Click the "download Portfolio Analysis" button`,
              action: `Click "download Portfolio Analysis" with code ${accessCode}`,
              label: `Click "download Portfolio Analysis" with code ${accessCode}`
            });
          }}
        >
          Download
        </button>
        <button
          className="downloadBtn bg-primary"
          onClick={() => {
            viewPdf(blobData);

            trackEvent({
              category: `Click the "View Your Portfolio Analysis" button`,
              action: `Click "View Your Portfolio Analysis" with code ${accessCode}`,
              label: `Click "View Your Portfolio Analysis" with code ${accessCode}`
            });
          }}
        >
          View
        </button>
      </div>
    </div>
  );
}

export default ViewPdf;
