import axios from 'axios';

export async function getAccessCode(code: string) {
    const res = await axios.get(`/accessCode/${code}`);

    return res.data as {
        valid: boolean,
        wasUsed: boolean,
        promptRequired: boolean
    };
}
