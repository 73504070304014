import React from 'react';
import './textPage.css';

interface Props {
    text: string;
}

const TextPage: React.FC<Props> = ({ text }) => {
    return (
        <div className='text-page'>
            <div className="shadow-box">
                <p dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </div>
    );
}

export default TextPage;
