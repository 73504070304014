import React from 'react';
import './inputContainer.css';

interface Props {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    label: string;
    error?: boolean;
    errorMessage?: string;
    type?: string;
    minLength?: number;
    required?: boolean;
}

const InputContainer: React.FC<Props> = ({ value, setValue, error, errorMessage, label, ...inputProps }) => {
    return (
        <div className={`input-wrap ${error && 'error'}`}>
            <label>{label}</label>

            <div className="input">
                <input value={value} onChange={e => setValue(e.target.value)} {...inputProps} />
                <small>{errorMessage && error && errorMessage}</small>
            </div>
        </div>
    );
}

export default InputContainer;
