import ReactGA from "react-ga4";
import { UaEventOptions } from "react-ga4/types/ga4";

ReactGA.initialize([{ trackingId: "G-DZB1RNRJBJ" }]);
ReactGA.ga("set", "checkProtocolTask", () => {});

export function trackEvent(event: UaEventOptions) {
  ReactGA.event(event);
}

export { ReactGA };

export default ReactGA;
