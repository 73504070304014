import React, { useState } from "react";
import { atom, useAtom } from "jotai";
import {
  currentStepAtom,
  portfolioAtom,
  promptChoicesAtom,
  generateLoading,
  backBtnAtom,
  logoAtom,
} from "../../GeneratePdf";
import "./confirm.css";
import { generatePdf } from "../../../../../actions/generatePdf";
import { accessCodeAtom } from "../../../home/Home";
import loading from '../../../../assets/loading.gif';
import { trackEvent } from "../../../../../GA";
import { toast } from "react-toastify";

export const pdfBlob = atom(null as any);

interface Props {
  step: number;
}

const Confirm: React.FC<Props> = ({ step }) => {
  const [backBtn] = useAtom(backBtnAtom);
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [promptChoices] = useAtom(promptChoicesAtom);
  const [isLoading, setIsloading] = useAtom(generateLoading);
  const [, setblobData] = useAtom(pdfBlob);
  const [accessCode] = useAtom(accessCodeAtom);
  const [name, setName] = useState("");
  const [logo, setLogo] = useAtom(logoAtom);

  const [portfolio] = useAtom(portfolioAtom);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    trackEvent({
      category: `Analyze Portfolio Button Click`,
      action: `Analyze Portfolio Button Click with code ${accessCode}`,
      label: `Analyze Portfolio Button Click with code ${accessCode}, recipient - ${name}`
    });

    try {
      setIsloading(true);
      const res = await generatePdf(
        {
          portfolio: portfolio,
          promptChoice: promptChoices,
        },
        name,
        accessCode,
        logo
      );
      setblobData(res);
      setCurrentStep((prev) => prev + 1);
      setLogo(null);

      trackEvent({
        category: `Code Used`,
        action: `Code (${accessCode}) has been used`,
        label: `Code (${accessCode}) has been used by recipient - ${name}`
      });
    } catch (error: any) {
      console.error(error);
      toast.error('Sorry, we are unable to generate a report. Please go back and select the proper two columns according to the instructions.');

      trackEvent({
        category: `Analyze Portfolio error`,
        action: `Analyze Portfolio failed with code ${accessCode}`,
        label: `Analyze Portfolio failed with code ${accessCode}`
      });
    }

    setIsloading(false);
  };

  if (currentStep !== step) {
    return <></>;
  }

  return (
    <>
      {!isLoading && backBtn}
      <form onSubmit={handleSubmit}>
        {!isLoading ? (
          <>
            <div className="text-cont">
              <h2> Step {step + 1}: Confirm</h2>
              <h2> Would you like to add a name to the report?</h2>
              <b> This is optional.</b> We ask for your name so we may include it
              in the PDF export of your portfolio analysis. If you’d like to
              continue without adding your name, you can leave the field blanks
              and may simply click “Analyze Portfolio” button below.
            </div>
            <input
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="name-input"
              type="text"
              placeholder="Name"
            />
            <div className="text-cont">
              <p>
                Reminder: We DO NOT save your name, portfolio details, or any
                other personally identifiable information.
              </p>
            </div>
            <div className="button-cont">
              <button type="submit" className="bg-primary btn1">
                Analyze Portfolio Now
              </button>
            </div>
          </>
        ) : (
          <div>
            <img src={loading} alt="loading..." width='74%' />
            <h2 style={{ marginTop: "12px" }}>Analyzing Portfolio</h2>
            <p>Populating....this may take up to two minutes.</p>
          </div>
        )}
      </form>
    </>
  );
};

export default Confirm;
