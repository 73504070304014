import axios from 'axios';

interface Prompt {
    id: number;
    title: string;
}

export async function getPrompts() {
    const res = await axios.get('/prompts');
    return res.data.prompts as Prompt[];
}
