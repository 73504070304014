import React from 'react';
import TextPage from '../../ui/text-page/TextPage';

export default function Accessibility() {
    return <TextPage text={accessibilityTtext} />;
}

const accessibilityTtext = `
Ethiq@ Accessibility Statement

Ethiq@ Accessibility Notice Statement

Ethiq@ has an ongoing commitment to achieving the highest levels of satisfaction for all aspects of its customers’ online experiences. As part of these efforts, Ethiq® is dedicated to offering a quality online experience at its website www.ethiq.com (the “Website”) and Google Chrome Extension (“Extension”) to customers with disabilities that is full and equal to that provided to customers without disabilities.
 
To assist in achieving the aforementioned accessibility goals with respect to the Website, Ethiq® has committed to the Website and Extension being designed, developed, and operated in substantial conformance with generally-recognized and accepted guidelines and/or standards for website accessibility (the “Standards”). While these Standards may change and/or evolve over time, they are currently the World Wide Web Consortium’s Web Content Accessibility Guidelines 2.0/2.1 at Levels A and AA (“WCAG”).
 
Looking forward to future updates with experienced accessibility consultants, Ethiq® is continuing to take the steps necessary to achieve substantial conformance with WCAG. The Website will continue to be assessed on a recurring basis from both an engineering and user-experience basis, including the use of assistive technology (such as screen readers and screen magnifiers), and the involvement of users with disabilities who use such assistive technologies.
 
Contact Ethiq® with any accessibility questions/feedback or to request features in future updates.
 
Please be aware that our efforts are ongoing. If, at any time, you have any specific questions, feedback, or concerns about the accessibility of any particular web pages on www.ethiq.com, the Extension, or the software platform itself, please contact us at info@ethiq.org. If you do encounter an accessibility issue, please be sure to specify the web page/URL in your email, and we will make all reasonable efforts to address your concerns.

`;
