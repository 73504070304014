import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

export default function Footer() {
    return (
        <footer>
            <div className='footer-container'>

                <div className="links">
                    <span>© {CURRENT_YEAR} Ethiq Inc.</span>

                    <div className='list'>
                        <Link to='/privacy'>Privacy</Link>
                        <Link to='/terms'>Terms</Link>
                        <Link to='/accessibility'>Accessiblity</Link>
                        <Link to='/do-not-sell'>Do Not Sell Info</Link>
                    </div>
                </div>

                <p style={{ textAlign: "center" }}>Ethiq® and Vote With Your Wallet® are registered trademarks of Ethiq Inc. {CURRENT_YEAR}. All rights reserved. Ethiq and other Ethiq products or services referenced here in are either trademarks or registered trademarks of Ethiq, Inc. Other products and/or company names referenced or mentioned on this site and in media published by Us may be trademarks of their respective owners. We have no affiliation with these companies or their owners, and our services are not endorsed by them.</p>
            </div>
        </footer>
    );
}

export const CURRENT_YEAR = new Date().getFullYear();
