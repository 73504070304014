import React from 'react';
import TextPage from '../../ui/text-page/TextPage';

export default function About() {
    return <TextPage text={aboutText} />;
}

const aboutText = `
<b>What is the purpose of Ethiq’s Google Chrome Extension?</b>

Ethiq’s Google Chrome Extension is a tool designed to show you monetary links between corporations and federal-level politicians. Our core mission is transparency through objective, factual data.

<b>How does Ethiq do this?</b>

Ethiq’s Google Chrome Extension scans the text of web pages for references to each of the 535 federal level politicians that make up the Senate and House of Representatives. Additionally, the extension scans for major corporations, their aliases and stock ticker symbols. The number of corporations and/or politicians identified on a given page will be notated by a number on the extension “badge” at the top right corner of the browser. We recommend you ensure the blue “pin” to the right of this badge is enabled so you may see this counter in action. Clicking on the badge will open the extension pop-up, featuring more detailed information. When politicians and corporations are found, Ethiq will display these for a user to see. Clicking on a corporation will open a list of politicians that received money from this entity. Clicking on a politician will open a list of corporations that allocated money to this individual. 

<b>Where does Ethiq’s data come from?</b>

Ethiq’s data are primarily sourced from Federal Election Commission and IRS 527 filings.

<b>I thought corporations weren’t allowed to give money directly to federal candidates and officeholders. Is this true?</b>

True. Although certain states allow corporations to give money directly to candidates and incumbents, this is not allowed at the federal level. Monetary relationships between corporations and federal officeholders are calculated by analyzing filings from a corporation’s associated Political Action Committees (PACs) and allocations by executive-level employees as mandated by the Federal Election Commission. 

<b>Where does Ethiq lean on the political spectrum – across political positions, ideologies and party lines?</b>

Ethiq is ideologically neutral. Transparency is the mission of this project. We are not attempting to make any claims that the relationships between corporations and politicians highlighted by our software are “good” or “bad.” We do, however, hope you find this information useful and additive to your web experience. 

<b>Who do I contact with questions, complaints or suggestions?</b>

Ethiq welcomes your feedback. Please email us at info@ethiq.org or use the feedback form on our main page.

`;
