import React from 'react';
import { CURRENT_YEAR } from '../../ui/footer/Footer';
import TextPage from '../../ui/text-page/TextPage';

export default function Privacy() {
    return <TextPage text={privacyText} />;
}

const privacyText = `
PRIVACY POLICY

Effective Date: November 16, ${CURRENT_YEAR}

NOTE: The use of information received from Google APIs will adhere to the <a style="text-decoration: underline;" href="https://developer.chrome.com/docs/webstore/program_policies/" target="_blank">Chrome Web Store User Data Policy</a>, including the <a href="https://developer.chrome.com/docs/webstore/program_policies/#limited_use" style="text-decoration: underline;" target="_blank">Limited Use</a> requirements.

Ethiq, Inc. (“Ethiq,” “We,” “Us,” “Our”) is committed to protecting the personal information You (“Beta Tester,” “You,” or a “User”) share and/or store with Us. This Privacy Policy applies to transactions and activities and data gathered through the Ethiq Website, Ethiq Chrome Extension, and any downloadable Application, as well as any interaction You may have with its related Social Media accounts.

We are currently beta testing Services that comprise, generally, aggregating factual data about political contributions made by businesses, corporate executives and their associated political action committees (PACs), as well as available statements and votes made by politicians, media personalities and candidates for office, so that You and other consumers may easily factor such political engagement into Your own purchasing, voting and investment decisions.

In advance of releasing Our Services to the general Public through Our Website, Chrome Extension (“Extension”) and downloadable Application, we are engaging with You, the Beta Testers, to further develop Our Services so that such Services may be prepared for use by Our intended audience in the coming months.

This Privacy Policy does not address, and We are not responsible for, the privacy, information, or other practices of any third parties, including any third party which We recommend or refer You to, or any third party operating any website to which this Website, Extension or Application contains a link. We are committed to providing you with non-partisan and unbiased information, thus, the inclusion of a link on this Website, Extension or Application does not imply endorsement of the linked website by Us or by Our affiliates.

For questions concerning this Privacy Policy, or Our use of Your personal information, cookies, or similar technologies, please contact us at (info@ethiq.org).

Personally Identifiable Information that You Provide to Us

We may collect certain personal information for identification that You, Our Beta Testers, provide to Us for reasons including, but not limited to:

Providing You with access to beta test software and other materials,
Receiving and responding to your feedback regarding the software and other materials that we provide to you,
Providing a better Website, Extension and Application,
Improving our Services, and
Communicating with You, Our Beta Testers, regarding Our products and services with third party products and services.

In particular, use of Our Website, Extension and Application as a beta tester will require that You disclose a unique email address and/or individualized login credentials to register an account on Our Website, Extension or Application.

We do not collect personally identifiable information e.g., name, e-mail address, contact or similar information unless you choose to provide it to us. If You voluntarily provide Us with personal information, for example by sending an e-mail or by filling out a form and submitting it through Our Website or Application, We may use that information only to respond to your message and to help Us provide You with the information or services that You request.

How this Information is Used

We use the information We collect to provide, analyze, administer, enhance, and personalize Our Services and marketing efforts. Where applicable, We may also use the information we collect to process Your registration and to communicate with You on this and other topics. For example, We use the information we collect for:

Determining your geographic location;
Determining your Internet service provider;
For product and service development purposes and to generally inform advertisers about the nature of Our user base to improve Our relationship with Our users; and
To customize Your experience by allowing any advertising to be targeted to the users for whom such advertising is most pertinent.

Direct Communication

We may periodically use Your information to communicate with You concerning Our Service (for example, by email, push notification and text messaging) so that We can send You news about Our Service, details about new features and content on Our Website, and special offers, promotional announcements (including those generated by third party product or service providers), and user surveys.

Non-Personal Information Collected Through Technology and Third Parties

During Your visit to Our Website, Extension or Application We automatically collect and store the following anonymous information about Your visit:

The dame and time You access Our site;
The pages You visit on Our site;
The amount of time spent engaging with any media available on Our site;
The internet service provider You use to connect to our site (for example "companyX.com" if You have a commercial internet account, or "universityX.edu" if You connect from a university); and
The IP address (a number automatically assigned to Your computer whenever You are surfing the internet) from which You access our site.

We may use this information, in the aggregate, to make our website, extension or application more useful to Our intended audience, to learn about the number of visitors to Our site and the types of technology used, to detect operational problems, and to improve the website's overall security.

Analytics, Log Files and Reading History

We may gather certain information automatically and store it in log files. This information may include IP addresses, browser type, operating system and other usage information about the use of Our Services, including a history of any Website, Extension or Application pages You view.

We may combine this automatically collected log information with other information We collect about You. We do this to improve services we offer You, including customized recommendations, advertising, to improve marketing, and to track access and use of Our Services across the devices that You may use to access Our Services.

We may hire third parties to provide Us information, reports and analysis about the usage, browsing patterns of Our beta testers. They may independently record the type of device and operating system You are using, general location information, as well as events that occur on Our Website, Extension and/or Application, such as how often you use Our Website and/or Application.

Third Party Persons or Entities with Whom We Share Personally Identifiable Information

We do not sell, rent, swap or authorize any third party to use your email address.

Third Parties

We may share information about our audience in aggregate form. Nothing in this Privacy Policy is intended to indicate a restriction of Our use or sharing of aggregated information acquired during beta testing in any way.

For instance, we may hire third parties to analyze Our users’ behavior, tastes and interests as submitted by You and other beta testers in an effort to develop our Services for general release. Similarly, we may use such data in combination with personal information that You provide Us with to predictively tailor Our Services, including recommendations and third party referrals, to your particular tastes, interests, and preferences.

Disclosure by Necessity

We may occasionally access, preserve, and/or disclose personal information as required by law, for example, to comply with a court order or subpoena or We have a good faith belief that such action is necessary to

Comply with the law or with legal process;
Protect and defend our rights and property;
Protect against misuse or unauthorized use of Our Services; or
Protect the personal safety or property of our users or the public (among other things, this means that if You provide false information or attempt to pose as someone else, information about You may be disclosed as part of any investigation into your actions).
As we continue to develop our Services, We or Our affiliates may sell or buy other businesses or entities, or we may merge with another company, or be bought by another company. In such transactions, personal information may be among the transferred assets.

Your information may be stored and processed in any country in which We maintain facilities or conduct operations. By using Our Website, Your personal information may be transferred outside of Your country of use and/or residence.

As the beta testing phase of Our business ends, your information may remain in our computers, electronic databases, and archives, and may not be reasonably capable of expungement.

Miscellaneous

Children's Guidelines

COPPA Compliance. We do not knowingly collect or store any personal information about children under the age of 13.

Amendments

This Privacy Policy may be amended by Us at any time and without notice, but only by amending this Policy as posted on this Website. Any amendments will become effective 30 days after being posted on the website, unless circumstances require that a change be immediately implemented.
`;
