import React from 'react';
import TextPage from '../../ui/text-page/TextPage';

export default function DoNotSell() {
    return <TextPage text={doNotSellText} />;
}

const doNotSellText = `
Do Not Sell My Personal Information

California’s California Consumer Privacy Act (“CCPA”) has redefined privacy in the U.S. and created many new rights for California consumers, including the right to opt out of the “sale” of their personal information.
 
At Ethiq®, we value privacy and support laws that increase transparency and promote privacy. We want to emphasize that we do not monetize your information, and we do not “sell” information in the traditional sense. “Sale” is defined broadly in CCPA to include any transfer of personal information for “valuable consideration”, which also includes transfers that are not considered a “sale” in the traditional sense, such as certain types of advertising.
 
If you wish to opt-out of these uses, please submit a request to info@ethiq.org
 
In the past 12 months, we have shared the following categories of information for a business purpose:
 
Name and Other Identifiers such as first and last name, alias or account name, email address, telephone number, postal address, and Internet Protocol address or other online unique identifier. The categories of third parties to whom information is shared are delivery, order and returns logistics service providers, payment processors, data storage service providers, analytics service providers, customer service providers, and technical and administrative
service providers.

Purchase History and Preferences: Records of products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies, including preferences indicated on our website or mobile application. The categories of third parties to whom information is shared are data storage service providers, analytics service providers, customer service providers, and technical and administrative service providers.

Network Activity Information including information regarding a consumer’s interaction with our website, mobile application, or advertisements, such as browsing or search activity. The categories of third parties to whom information is shared are data storage service providers, analytics service providers, and technical and administrative service providers.
Geolocation Data. The categories of third parties to whom information is shared are data storage service providers, analytics service providers, and technical and administrative service providers.

Payment Information. The categories of third parties to whom information is shared are payment processors.
 
In the past 12 months, we have shared the following categories of information for valuable consideration. These categories of information have been shared to facilitate advertising.
 
Name and Other Identifiers such as first and last name, alias or account name, email address, telephone number, postal address, and Internet Protocol address or other online unique identifier.

Network Activity Information including information regarding a consumer’s interaction with our website, mobile application, or advertisements, such as browsing or search activity.
Geolocation Data.
 
If you have questions or want more information on the above, please see our Privacy Policy or contact us at info@ethiq.org

`;
