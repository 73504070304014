import React from 'react';
import {
  Route,
  Routes,
  BrowserRouter as Router,
} from 'react-router-dom';
import About from './routes/about/About';
import Accessibility from './routes/accessibility/Accessibility';
import GeneratePdf from './routes/generate-pdf/GeneratePdf';
import DoNotSell from './routes/do-not-sell/DoNotSell';
import ForgotPassword from './routes/forgot-password/ForgotPassword';
import Home from './routes/home/Home';
import Privacy from './routes/privacy/Privacy';
import Terms from './routes/terms/Terms';

import Footer from './ui/footer/Footer';
import Header from './ui/header/Header';
import ToastMessage from './ui/toast-message/ToastMessage';
import { QueryClient, QueryClientProvider } from 'react-query';

export const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Header />
        <ToastMessage />
        <Routes>
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/about' element={<About />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/do-not-sell' element={<DoNotSell />} />
          <Route path='/accessibility' element={<Accessibility />} />
          <Route path='/forgot-password/:token' element={<ForgotPassword />} />
          <Route path='*' element={<Home />} />
          <Route path="/generate-pdf" element={<GeneratePdf />} />
        </Routes>
        <Footer />
      </Router>
    </QueryClientProvider>
  );
}

export default App;
