import React from "react";
import "../routes/home/home.css"

export default function MobileView() {
  return (
    <div className="mobile-cont">
      <p>
        The Ethiq® Chrome Extension and Ethiq® Portfolio Visualizer are
        currently not available on mobile devices.
      </p>
      <h5>Please visit ethiq.com on a desktop/laptop.</h5>
    </div>
  );
}
