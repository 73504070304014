import axios from "axios";

type TInput = {
  portfolio: {
    stockTicker: string;
    value: number;
  }[];
  promptChoice: {
    promptId: number;
    choiceNumber: number;
  }[];
};

export async function generatePdf(
  input: TInput,
  recipient: string,
  code: string,
  logo: File | null
) {
  const formData = new FormData();
  formData.append("body", JSON.stringify({ input, recipient, code }));

  if (logo) {
    formData.append("file", logo);
  }

  const res = await axios.post("/generatePdf", formData, {
    responseType: "blob",
  });

  return res.data;
}

export function downloadPdf(data: BlobPart) {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: "application/pdf" })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "EthiqPortfolioAnalysis.pdf");
  document.body.appendChild(link);
  link.click();
}
export function viewPdf(data: BlobPart) {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: "application/pdf" })
  );
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.setAttribute("open", "EthiqPortfolioAnalysis.pdf");
  document.body.appendChild(link);
  link.click();
}
