import React, { useCallback, useRef } from "react";
import { PdfSvg } from "../../../../assets/Svgs";
import { useAtom } from 'jotai';
import { backBtnAtom, currentStepAtom, logoAtom } from "../../GeneratePdf";

const UploadLogo: React.FC<{ step: number; }> = ({ step }) => {
    const [backBtn] = useAtom(backBtnAtom);
    const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
    const [logo, setLogo] = useAtom(logoAtom);

    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    const handleSubmit = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();

        setCurrentStep(prev => prev + 1);
    }, [setCurrentStep]);

    if (currentStep !== step) {
        return <></>;
    }

    return (
        <>
            {backBtn}
            <form onSubmit={handleSubmit}>
                <div className="text-cont">
                    <h2> Step {step + 1}: Upload your company’s logo (optional)</h2>
                    If you’d like this report branded with your company’s logo, please click “upload logo” below and select a JPG, PNG or SVG logo file.
                </div>
                <div className="button-cont">
                    {logo ? (
                        <div className="csvName-cont">
                            <div className="align-center justify-center">
                                <span style={{ fontSize: "26px" }} className="ft4" >   <PdfSvg />{" "}{logo?.name}</span>
                            </div>
                            <input
                                ref={ref}
                                type="file"
                                accept="image/png, image/jpeg, image/svg+xml"
                                onChange={(e) => {
                                    if (e.target.files?.[0]) {
                                        setLogo(e.target.files[0]);
                                    }
                                }}
                                style={{ display: "none" }}
                            />
                            <button
                                type="button"
                                className="btn"
                                onClick={() => {
                                    ref.current.click();
                                }}
                            >
                                CLICK HERE TO UPLOAD A DIFFERENT LOGO
                            </button>
                        </div>
                    ) : (
                        <>
                            <button
                                onClick={() => ref.current.click()}
                                className="btn1 bg-primary"
                                type="button"
                            >
                                Upload Logo
                            </button>
                            <input
                                ref={ref}
                                type="file"
                                accept="image/png, image/jpeg, image/svg+xml"
                                onChange={e => e.target.files?.[0] && setLogo(e.target.files[0])}
                                style={{ display: "none" }}
                            />
                        </>
                    )}
                    <button
                        type='submit'
                        className={`btn2 bg-primary font-white`}
                    >
                        {logo ? 'Continue' : 'Skip'}
                    </button>
                </div>
            </form>
        </>
    );
};

export default UploadLogo;
